<template>
    <div class="page-warp gray-page min-list">
        <van-tabs v-model="active" @click="resetLoad()">
            <van-tab title="全部"></van-tab>
            <van-tab name="5" title="待支付"></van-tab>
            <van-tab name="6" title="已支付"></van-tab>
            <van-tab name="1,2,3,4" title="已取消"></van-tab>
        </van-tabs>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            >
            <div class="row loop-list mt-10" v-for="(item,inx) in loopList" :key="inx">
                <div class="row loop-title"><span class="nums">申报单号：SB20228376748838</span><span class="btns">待支付</span></div>
                <div class="row loop-cons">
                    <span style="font-size:12px;">{{item.companyName}}</span>
                    <span style="font-size:12px;">创建时间：2021-07</span>
                    <span style="font-size:12px;">订单类型：个体注册</span>
                    <span style="font-size:12px;">支付金额：1622.92元</span>
                </div>
                <div class="row loop-bottom">
                    <div class="o-b-msg">订单还有15分钟失效</div>
                    <div class="o-b-btn ons">去支付</div>
                    <div class="o-b-btn">取消支付</div>
                </div>
                <!-- <van-cell class="loop-list-min-title">
                    <template #title>
                        <span>{{item.companyName}}</span>
                        <div class="user-status" :class="`user-status-c${item.companyStatus}`">{{item.companyStatusStr}}</div>
                    </template>
                </van-cell>
                <van-cell-group>
                    <van-cell title="园区" :value="item.gardenName" />
                    <van-cell title="手机号" :value="item.linkerMobile" />
                    <van-cell title="姓名" :value="item.linkerName" />
                    <van-cell title="创建时间" :value="item.createTime" />
                </van-cell-group> -->
            </div>
        </van-list>
    </div>
</template>
<script>
import { pageQuery } from "@/api/client"
export default {
    name:'order',
    data() {
        return {
            active:'',
            loading: false,
            finished: false,
            page:1,
            pageSize:10,
            loopList:[],
        };
    },
    methods: {
        resetLoad(){
            this.loopList = [];
            this.page = 1;
            this.finished = false;
        },
        async onLoad() {
            // 异步更新数据
            let pramas={
                page:this.page,
                pageSize:this.pageSize
            }
            if(this.active){
                pramas.companyStatusS = this.active;
            }
            this.loading = true;
            await pageQuery (pramas).then(res=>{
                if (res.rows.length < this.pageSize) {
                    this.finished = true;
                }
                res.rows.map(item=>{
                    this.loopList.push(item);
                })
                this.loading = false;
            })
        },
    },
};
</script>
<style lang="less" scoped>
</style>
